<template>
  <div :class="chartButtonContainerClass" v-show="display" data-html2canvas-ignore="true">
    <div class="chart-button-column">
      <button id="snap_chart_button" class="btn" v-on:click="snapChart()">
        <i v-tooltip="snapBothTooltipConfig" class='fa-duotone fa-camera fa-lg'></i>
      </button>
    </div>
    <div v-show="includeAdvisorButton && !$parent.isInsiderUser" class="chart-button-column">
      <button class="btn ml-4" type="button" @click="$parent.displayInsights(insightModalId, reportPageTypeName)">
        <i class='fa-duotone fa-thought-bubble fa-lg'></i>
      </button>
    </div>
    <div class="d-none chart-button-column">
      <div class="caption">Download</div>
      <button class="btn ml-4" type="button" @click="downloadPowerPoint">
        <font-awesome-icon :icon="['fas', 'file-powerpoint']" size="lg" />
        <div>Powerpoint</div>
      </button>
    </div>
    
  </div>
</template>
<script>
import AlertService from "../../services/alert.service";
import GoogleService from "../../services/google.service";
import PowerPointService from "../../services/power.point.service";
import PrintAndCopyService from "../../services/print.and.copy.service";
import ContentMixins from "../../mixins/ContentMixins.vue";
import Vue from "vue";
import VTooltip from "v-tooltip";
Vue.use(VTooltip);

export default {
  name: "ChartButtons",
  mixins: [ContentMixins],
  components: {},
  data() {
    return {
      downloadPowerPointModalContent: PowerPointService.getModalMessage(),
      snapModalContent: PrintAndCopyService.getSnapMessage(),
      description: "",
      displayLegend: true,
      displayKeyOnly: false,
      reportPageInput: {},
      snapBothTooltipConfig: {
        content: "Take snapshot of the chart. ",
        trigger: "hover",
        delay: { show: 100, hide: 100 }
      },
      snapLegendTooltipConfig: {
        content: "Click to take snapshot of the legend. ",
        trigger: "hover",
        delay: { show: 500, hide: 500 }
      },
    };
  },
  props: {
    includeAdvisorButton: {
      type: Boolean,
      default: true
    },      
    snapLabel:{
      type: String,
      default: "Both"
    },
    textDirection:{
      type: String,
      default: "horizontal"
    },
    containerId: {
      type: String,
      default: "chart-container"
    },
    snapContainerId: {
      type: String,
      default: "chart-container"
    },
    containerClass: {
      type: String,
      default: ""
    },
    includeRemoveBox: {
      type: Boolean,
      default: true
    },
    display: {
      type: Boolean,
      default: false
    },
    tooltipConfiguration: {
      type: Object,
      default: function() {
        return {};
      }
    },
    insightModalId: {
      type: String,
      default: ""
    },
    powerPointContainerId: {
      type: String,
      default: ""
    },
    reportPageTypeName: {
      type: String,
      default: ""
    }
  },
  computed: {
    chartButtonContainerClass: function() {
      let returnClass = "chart-button-container";
      if (this.containerClass != "") {
        returnClass += " ";
        returnClass += this.containerClass;
      }
      return returnClass;
    }
  },
  mounted() {},
  created() {},
  methods: {
    resetSnapOptions(){
      this.displayLegend = true;
      this.displayKeyOnly = false;
    },
    async downloadPowerPoint() {
      AlertService.showInProgressMessage('Download in progress....');
      const vm = this;
      GoogleService.logClickEvent(vm.reportPageTypeName, "Download PowerPoint", 0, vm);
      await this.$parent.downloadPowerPoint(this.powerPointContainerId);
      AlertService.closeAlert();
    },
    async snapChart() {
      // eslint-disable-next-line no-console
      //console.log("ChartButtons::snapChart()");
      AlertService.showInProgressMessage(this.snapModalContent,'Snap in progress....');
      const vm = this;
      setTimeout(async function() {
        GoogleService.logClickEvent(vm.reportPageTypeName, "Snap", 0, vm);

        const vm2nd = vm;
        PrintAndCopyService.snapChart(vm.snapContainerId, vm, vm.includeRemoveBox, vm.displayLegend, vm.displayKeyOnly).then(function() {
          AlertService.closeAlert(0);
          vm2nd.resetSnapOptions();
        });
      }, 1000);
    },
    printChart() {
      GoogleService.logClickEvent(this.reportPageTypeName, "Print",  0, this);

      PrintAndCopyService.printChart(this.containerId);
    }
  },
  watch: {}
};
</script>
