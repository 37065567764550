import $, { type } from "jquery";
import ScatterService from "./scatter.service";
export default class CommonService {
    /*** xAxis Functions ***/
    getBaseXAxis(){
      return {
          type: 'category',
          gridIndex: 0,
          data: [],              
          axisLabel: {
              fontSize: 10.5,
              fontFamily: "Franklin Gothic Demi Cond",
              color: "#000",
          },
          axisTick:{
              show: false,
          },   
          axisLine: {
            show: false,
          },
          splitLine:{}     
      };
    }    
    getXAxis(gridIndex = 0){
      let xAxis = this.getBaseXAxis()
      let gridIndexForAxis = {
          gridIndex: gridIndex,
      }
      $.extend(true, xAxis, gridIndexForAxis);          
      return xAxis;
    }    
    getEmptyXAxis(){
      return     {
        type: "category",
        gridIndex: 0,
        data: [],
        axisLabel: {
          show: false
        },
        axisTick: {
          show: false
        },
        axisLine: {
          show: false
        },
        splitLine: {
          show: false,
        }
      };      
    }
    getXAxisForNeeds(displayType = 'need',breakPointList = [],showSplitLine = false){
      let xAxis = this.getBaseXAxis()
      // add one to each entry in the breakPointList
      breakPointList = breakPointList.map(x => x + 1);
      let splitLine = {
          show: showSplitLine,
          lineStyle: {
              color: "#C0C0C0",
          },
          interval: function(index, value) {
            return breakPointList.includes(index);              
          },        
      };             
      if (displayType == "match-moat") {
        let splitLineForMoat = {        
            color: "#1c408e",
            width: 1,
        }
        splitLine.lineStyle = JSON.parse(JSON.stringify(splitLineForMoat));
      }        
      $.extend(true, xAxis.splitLine, splitLine);          
      $.extend(true, xAxis, this.getXAxis(0));          
      return xAxis;
  }
    /*** yAxis Functions ***/
    getBaseYAxis(){
        let axisLabel = {}   
        $.extend(true, axisLabel, ScatterService.getDefaultAxisTextStyle());          
        let baseYAxis = {
            type: 'value',
            gridIndex: 0,
            axisLabel: axisLabel ,
            splitLine: {
                lineStyle: {
                color: "#fff",
                }
            },       
            min: 1,
            axisTick: {
                show: false
            }, 
            axisLine: {
              show: false,
            },
        };        
        return baseYAxis;
    }
    getYAxisForPricingPower(maxPricePower = 5){
        let axisLabelForPricingPower = this.getAxisLabelForPercent();
        $.extend(true, axisLabelForPricingPower, ScatterService.getDefaultAxisTextStyle());           
        let yYAxisForPricingPower = this.getBaseYAxis();
        let yYAxisForPricingPowerAdditional = {
            boundaryGap: true,
            splitLine: {
                show: false
            },
            axisLine: {
                show: false,
            },
            axisLabel: axisLabelForPricingPower,
            min: 0,
            max: maxPricePower
        };
        $.extend(true, yYAxisForPricingPower, yYAxisForPricingPowerAdditional);          
        return yYAxisForPricingPower;
    }
    getYAxisForNeed(min = 1, max = 10){
        let yAxisForNeed = this.getBaseYAxis()
        let yAxisForNeedAdditional = {
            min: min,
            max: max,
            splitNumber: 10,
        }
        $.extend(true, yAxisForNeed, yAxisForNeedAdditional);   
        return yAxisForNeed;
    }    
    getDefaultMarkLineForMoat(){
      return {
          symbol: "none",
          label: {
            show: false
          },
          data: [
            {
              yAxis: 10,
              lineStyle: {
                color: "#F4E0E0",
                type: "solid",
                width:2
              },            
            },
            {
              yAxis: 35,
              lineStyle: {
                color: "#FFFF66",
                type: "solid",
                width:2
              },            
            },
            {
              yAxis: 75,
              lineStyle: {
                color: "#EBF1DE",
                type: "solid",
                width:2
              },       
            },
            {
              yAxis: 90,
              lineStyle: {
                color: "#D8E4BC",
                type: "solid",
                width:2
              },            
            }          
          ]
        }
  }    
    getDefaultMarkLineForEmotionalNeeds(lineColor = "#C0C0C0"){
      return {
        name: 'line',
        symbol: 'none',
        label: {
          show: false
        },
        lineStyle: {
          color: lineColor,
          type: 'solid'
        },
        data: [
          {
            name: 'start',
            yAxis: 5.5,
          }          
        ]
      };   
    }
    /***  Series Functions ***/
    getMetricSeries(name, gridIndex,xAxisIndex,yAxisIndex,itemColor = "rgb(215, 228, 189)",useFixedBarWidth = true){
      let metricBarSeries = {
              name: name,
              type: "bar",
             // barWidth: 25,
              gridIndex: gridIndex,
              xAxisIndex: xAxisIndex,
              yAxisIndex: yAxisIndex,
              itemStyle: {
                  color: itemColor
              },
              data: []
      };
      if (useFixedBarWidth == true){
        $.extend(true, metricBarSeries, {barWidth: 25});
      }
      return metricBarSeries;
    }    
    /***  Parse Functions ****/
    parseLegendAndColorData(inputData, outputData, moveFirstColorToEnd = true){
        if (typeof inputData !== 'undefined' && inputData !== null) {
          if (typeof inputData.options !== 'undefined' && inputData.options !== null) {
            if (typeof inputData.options.colors !== 'undefined' && inputData.options.colors !== null) {
              outputData.colors = inputData.options.colors;
              if (moveFirstColorToEnd == true) {
                // move the first element in the array to the end of the array
                let firstColor = outputData.colors.shift();
                outputData.colors.push(firstColor);
              }
            }
          }
          if (typeof inputData.otherData !== 'undefined' && inputData.otherData !== null) {
            let otherData = inputData.otherData;
            let pricingPowerSeriesNumber = otherData["pricingPowerSeriesNumber"];
            if (typeof pricingPowerSeriesNumber != "undefined") {
              outputData.pricingPowerSeriesNumber = pricingPowerSeriesNumber;
            }          
            if (typeof otherData.legend !== 'undefined' && otherData.legend !== null) {
              outputData.legend = {
                normal: [],
                respondents: []
              };
              let inputLegend = otherData["legend"];
              for (let i = 0; i < inputLegend.length; i++) {
                let legend = inputLegend[i];
                if (typeof legend.column !== 'undefined' && legend.column !== null) {
                  let respondentsName = legend.count != 0 ? legend.baseName + " (n=" + legend.count + ")" : legend.baseName;
                  let outputLegend = {
                    elementId: legend.elementId,
                    name: legend.baseName,
                    count: legend.count,
                    symbolClass: legend.symbolClass,
                    symbolColor: legend.symbolColor
                  };
                  outputData.legend.normal.push(outputLegend);
                  let respondentOtputLegend = {
                    elementId: legend.elementId,
                    name: respondentsName,
                    count: legend.count,
                    symbolClass: legend.symbolClass,
                    symbolColor: legend.symbolColor
                  };
                  outputData.legend.respondents.push(respondentOtputLegend);
                }
              }
            }
            outputData.breakPointList = [];
            // get tableStakesCount coreCount divergeCount
            if (typeof otherData.breakPointList !== 'undefined' && otherData.breakPointList !== null){
              outputData.breakPointList = JSON.parse(JSON.stringify(otherData.breakPointList));
            }
            outputData.winnerMapNames = [];
            if (typeof otherData.winnerMapNames !== 'undefined' && otherData.winnerMapNames !== null){
              outputData.winnerMapNames = JSON.parse(JSON.stringify(otherData.winnerMapNames));
            }
            outputData.needsList = [];
            if (typeof otherData.needsList !== 'undefined' && otherData.needsList !== null){
              outputData.needsList = JSON.parse(JSON.stringify(otherData.needsList));
              let seenNeedNames = new Set();
              outputData.needsList = outputData.needsList.filter(item => {
                if (seenNeedNames.has(item.needName)) {
                  return false;
                } else {
                  seenNeedNames.add(item.needName);
                  return true;
                }
              });            
            }
            outputData.driverList = [];
            if (typeof otherData.driverList !== 'undefined' && otherData.driverList !== null){
              outputData.driverList = JSON.parse(JSON.stringify(otherData.driverList));
            }
          }
        }
        return outputData;
    }
    parseData(chartNumber, inputData, outputData, itemsToRemove, includePricingPower = true){
        if (chartNumber == 1) {
          outputData.chart1 = {
            data: [],
            needsList: [],
            containerId: ""
          };
        } else if (chartNumber == 2) {
          outputData.chart2 = {
            data: [],
            needsList: [],
            containerId: ""
          };
        }      
        if (includePricingPower == true) {
          itemsToRemove.lastColumns = 1;
        }
        if (typeof inputData !== 'undefined' && inputData !== null) {      
          if (typeof inputData.rows !== 'undefined' && inputData.rows !== null) {      
            let rows = inputData.rows;
            let removeFirstRowCount = 0;
            while (itemsToRemove.firstRows > removeFirstRowCount) {
              let indexToRemove = 0;
              rows.splice(indexToRemove, 1);
              removeFirstRowCount++;
            }

            let removeRowCount = 0;
            while (itemsToRemove.lastRows > removeRowCount) {
              let indexToRemove = rows.length - 1;
              rows.splice(indexToRemove, 1);
              removeRowCount++;            
            }
            for (let i = 0; i < rows.length; i++) {
              let row = rows[i];
              let rowData = [];
              if (typeof row.c !== 'undefined' && row.c !== null) {
                let columns = row.c;
                for (let j = 0; j < columns.length; j++) {
                  let column = columns[j];
                  if (typeof column.v !== 'undefined' && column.v !== null) {
                    rowData.push(column.v);
                  }
                }
              }
              rowData = this.removeColumns(rowData, itemsToRemove);
              if (chartNumber == 1) {
                outputData.chart1.data.push(rowData);
              } else if (chartNumber == 2) {
                outputData.chart2.data.push(rowData);
              } 
            }
          }
          if (typeof inputData.containerId !== 'undefined' && inputData.containerId !== null) {      
            if (chartNumber == 1) {
              outputData.chart1.containerId = inputData.containerId;
            } else if (chartNumber == 2) {
              outputData.chart2.containerId = inputData.containerId;
            }          
          }
        }
        return outputData;
    }
    removeColumns(rowData, itemsToRemove){
        // remove the last columns
        let removeColumnCount = 0;
        while (itemsToRemove.lastColumns > removeColumnCount) {
          let indexToRemove = rowData.length - 1;
          rowData.splice(indexToRemove, 1);
          removeColumnCount++;            
        }
        // remove the first columns
        let removeFirstColumnCount = 0;
        while (itemsToRemove.firstColumns > removeFirstColumnCount) {
          let indexToRemove = 0;
          rowData.splice(indexToRemove, 1);
          removeFirstColumnCount++;
        }
        if (typeof itemsToRemove.gapColumn != "undefined" && itemsToRemove.gapColumn != null){
          rowData.splice(itemsToRemove.gapColumn, 1);
        }
        // Remove the other columns last
        if (typeof itemsToRemove.otherColumns != "undefined" && itemsToRemove.otherColumns != null && itemsToRemove.otherColumns.length > 0){
          for (let i = 0; i < itemsToRemove.otherColumns.length; i++) {
            rowData.splice(itemsToRemove.otherColumns[i], 1);
          }
        }
        return rowData;
    }

    /***  Axis Label Functions*/  
    getDefaultAxisTextStyleForAnalyticChart() {
      let showAllLabel = {      
          showMinLabel: true,
          showMaxLabel:true,
      }
      let label = ScatterService.getDefaultAxisTextStyle();
      $.extend(true, label, showAllLabel);        
    }    
    getAxisLabelForMetric(formatter){
        return {
            color: "#339966",
            show: true,
            formatter: formatter,
        };
    }
    getAxisLabelForPercent(){
        return {
            color: "#339966",
            show: true,
            formatter: "{value}%",
        };
    }
    getToolboxForAnalyticsChart(){
      return {
        height: 'auto',
        left: 0,
        //top: 90,            
      }      
    }    
    getGridForSingleChart(includeBackgroud = true) {
      let grid1 = {
//          left: "10%",
          top:80,
     //     right: 5,
          //width: "90%",
          containLabel: true,
          bottom: "2.5%",
      };
      if (includeBackgroud == true){
          $.extend(true, grid1, ScatterService.getGridBackground());
      }
      let baseGrid = [];
      baseGrid.push(grid1);
      return baseGrid;
    }   
    /*** Header Functions */     
    getBaseNeedsHeader(breakPointList = [],numberOfNeeds = 14,displayType = 'need'){
      breakPointList = breakPointList.map(x => x + 1);
      let needsArray = [];
      for (let i = 0; i < numberOfNeeds; i++) {
        needsArray.push(i + 1);
      }
      let baseHeader = {
        gridIndex: 0,
        data: needsArray,
        position: "top",
        scale: 'value',
        axisTick: {
          show: true,
          length: 0,
          lineStyle: {
            color: '#000'
          },
          interval: function(index, value) {
            return breakPointList.includes(index);              
          },        
        },
        axisLabel: {
          show: false,
        },
        axisLine: {
          show: false,
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: "#C0C0C0"
          },
          interval: function(index, value) {
            return breakPointList.includes(index);              
          },        
        },            
      };
      if (displayType == "winner" || displayType == "match-winner" || displayType == "moat" || displayType == "match-moat" || displayType == "moat-ranks") {
        let splitLineForWinner = {        
            color: "#fff",
            width: 4,
        }
        baseHeader.splitLine.lineStyle = JSON.parse(JSON.stringify(splitLineForWinner));
      }
      return baseHeader;              
    }
    getMoatHeaderSeries(displayType, names = [], numberOfNeeds = 14, gridIndex = 0, xAxisIndex = 1, yAxisIndex = 0,displayPricingPower = true){
      let isEmotional = names.length == 5;
      let yellow = ['Design','Innovation','Routine','Curiosity'];
      let gold = ['Scale','Time','Skill','Strictness'];
      let lightBrown = ['Lock-In','Brand','Trust'];
      let brown = ['Network','Activity'];
      let darkBrown = ['Value','Lock-Out','Assurance'];

      let finalData = [];
      let base =  {           
        name: '',
        itemStyle: {  
          color: 'none',
        },
        label: {
          width: 33.5,
          overflow: 'overflow',
          ellipsis: '...',                    
          rich: {
            a : {
              align: 'center',
              fontSize: 8.5,
              fontFamily: "Franklin Gothic Medium Cond",                    
              lineHeight: 35,
              color: '#fff',
              verticalAlign: 'middle',
            }
          }
        }
      };
      let colorList = [];
      let backgroundColorList = [];
      let startList = [];
      let endList = [];
      for (let i = 0; i < names.length; i++) {
        if (isEmotional || displayType == "moat-ranks"){
          startList.push(i);
          endList.push(i);
        } else {
          startList.push(i+1);
          endList.push(i+1);
        }
        if (yellow.includes(names[i])) {
          backgroundColorList.push('#ffe48f')
          colorList.push('#000');
        } else if (gold.includes(names[i])) {
          backgroundColorList.push('#ffcd2f')
          colorList.push('#000');
        } else if (lightBrown.includes(names[i])) {
          backgroundColorList.push('#bf9000')
          colorList.push('#000');
        } else if (brown.includes(names[i])) {
          backgroundColorList.push('#826300');
          colorList.push('#fff');
        } else if (darkBrown.includes(names[i])) {
          backgroundColorList.push('#4c3a00')
          colorList.push('#fff');
        }
      }
      endList.push(String(numberOfNeeds));
      for (let i = 0; i < names.length; i++) {
        let newCoord = JSON.parse(JSON.stringify(base));
        let xCood = []
        // 1st Coord
        $.extend(true, newCoord, {name: names[i]});
        if (displayType == "moat-ranks"){
          if (names[i] == 'Brand') {
            newCoord.label.width = 54;
          } else {
            newCoord.label.width = 54;
          }
        } else if (isEmotional) {
          newCoord.label.width = 43;
        } else if (names[i] == 'Value') {
          newCoord.label.width = 69.5;
        }
        if (displayPricingPower == false) {
          if (isEmotional){
            newCoord.label.width += 2.75;  
          }
          newCoord.label.width += 1.75;
        }
        $.extend(true, newCoord.label, {formatter: '{a|'+names[i]+'}'});
        $.extend(true, newCoord.label, {backgroundColor: backgroundColorList[i]});  
        $.extend(true, newCoord.label.rich.a, {color: colorList[i]});  
        xCood.push(newCoord);
        if (i > 0) {
          if (displayType == "moat-ranks" && names[i] == 'Brand') {
            newCoord.xAxis = 10;
          } else {
            newCoord.xAxis = startList[i]
          }
        } else if (i == 0) {
          if (isEmotional) {
            newCoord.label.offset = [10.5,0];
          } else if (displayType == "moat-ranks") {
            newCoord.label.offset = [12,0];
          } else {
            newCoord.label.offset = [7,0];
          }
        }
        // 2nd Coord
        if (displayType == "moat-ranks" && names[i] == 'Brand') {
          xCood.push({xAxis: 10});
        } else {
          xCood.push({xAxis: endList[i]});
        }
        finalData.push(xCood);
      }
      return {
        name: "Header",
        type: "scatter",
        label: {
          "show": false,
        },
        xAxisIndex: xAxisIndex,
        yAxisIndex: yAxisIndex,
        gridIndex: gridIndex,
        markArea: {
          label: {
            backgroundColor: '#2F5EB3',
            color: 'white',
            padding: [0,0,10,0],
            fontSize: 7,
            fontWeight: 'bold',
            height: 24,
            distance: 1,
          },
          data: finalData
        },
      };
    }    

    /*** Base Functions ***/
    getDefaultLineStyle(){
      return {
          lineStyle:{
              width: 5
          }
      };
    }
    getBaseConfig(symbolType = "none"){
        let symbol = {
            symbol: symbolType,
            zLevel: 10,
            z:10,
        };
        $.extend(true,symbol,this.getDefaultLineStyle());
        return symbol;
    }   
    getCompanyConfig(symbolType = "none",symbolSize = 10){
        let symbol = {
            symbol: symbolType,
            symbolSize: symbolSize,
            zLevel: 12,
            z:12
        };
        $.extend(true,symbol,this.getDefaultLineStyle());
        return symbol;
    }       
    findMinMax(numbersArray,useMetricGrowth = false) {
      if (numbersArray.length === 0) return { min: null, max: null }; // Handle empty array
      let min = Math.min(...numbersArray);
      let max = Math.max(...numbersArray);

      if (useMetricGrowth == true){
        if (this.max < 100) {
          max = 100;
        } else {
          max = null;
        }
      } else {
        max = null;
      }
      if (min < 0) {
        min = null;
      } else {
        min = 0;
      }
    return { min: min, max: max };
  }    
}

