<template>
  <vue-modal :key="refreshModal" :ref="modalId" :name="modalId" :clickToClose="false" :draggable="true" @before-open="beforeOpen" :adaptive="true" :scrollable="true" width="50%" :maxHeight="1550" :height="'auto'" >
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{ modalTitle }}</h5>
        <button type="button" class="close" @click="closeModal(modalId)">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div id="modal-chart" class="modal-body  mx-auto d-flex">
        <div class="dashboard-button-container">
            <chart-buttons
            :display="true"
            :tooltip-configuration="snapChartTooltipConfig"
            :snap-container-id="'modal-chart'"
            :snap-label="'Snap'">
          </chart-buttons>
          <div v-show="displayAdvisorButton" data-html2canvas-ignore="true" class="chart-button-container">
            <div class="chart-button-column">
              <button class="btn ml-4" type="button" @click="displayInsights(dashboardInsightModalId, dashboardCardReportPageTypeName)">
                <i class='fa-duotone fa-thought-bubble fa-lg'></i>                                
              </button>
            </div>
          </div>
        </div>
        <div class="container">
          <div v-show="displayDashboard" id="analytics-contributions-container" class="predictive-analytics-echart">
                <div class="contribution"
                    v-for="(contributionChart, idxContributionChart) in this.modalContributionChartList"
                    v-bind:key="idxContributionChart">
                            <predictive-analytics-contribution-chart 
                            :include-coorelation-map="!inputIsPricingPower"
                            :is-dashboard-card="true"
                            :is-saved-card="clientChart.clientChartId != 0" 
                            :contribution-chart="contributionChart" 
                            @save-image="$parent.saveImage"
                            v-if="displayDashboard"/>

                </div>
            </div>
        </div>
      </div>
      <div class="modal-footer">
        <button id="save_chart" class="btn btn-primary ml-2" type="button" @click="$modal.show('modal-save-chart-confirmation')">
          Save for Report
          <span class="m-1">
              <font-awesome-icon :icon="['fa', 'floppy-disk']" />
            </span>          
        </button>
        <button type="button" class="btn btn-primary ml-4" @click="closeModal(modalId)">Close</button>
      </div>
    </div>
    <insights
      ref="insightsDashboardModal"
      :modal-name="dashboardInsightModalId"
      :report-page-type-name="dashboardCardReportPageTypeName"
      :entry-page-content="this.$parent.pageContentForReport"
      @saveReportPage="updateReportPages"
      @saveAllReportPages="saveAllReportPages"
      @saveClientChart="saveClientChart"
    />    
    <vue-modal ref="modal-save-chart-confirmation" name="modal-save-chart-confirmation" :width="350" :height="200">
      <save-chart modal-name="modal-save-chart-confirmation" button-label="Save for Report" :chart-title="this.chartTitle" :initial-client-chart="this.clientChart" @save="this.$parent.updateClientCharts" @closeModal="closeSaveChartModal('modal-save-chart-confirmation')"></save-chart>
    </vue-modal>
  </vue-modal>
</template>

<script>
import ChartButtons from "../components/chart/ChartButtons.vue";
import SaveChart from "../components/chart/SaveChart.vue";
import Insights from "../modals/Insights.vue";
import ReportService from "../services/report.service";
import AdminService from "../services/admin.service";
import MetricsService from "../services/metrics.service";
import DashboardService from "../services/dashboard.service";
import PredictiveAnalyticsContributionChart from "../views/echarts/PredictiveAnalyticsContributionChart.vue";

import $ from "jquery";

export default {
  name: "DashboardPredictiveAnalyticsContributionsModal",
  mixins: [],
  components: {PredictiveAnalyticsContributionChart,ChartButtons,SaveChart,Insights},
  data() {
    return {
      displayDashboard: false,
      dashboardInsightModalId: "",
      reportPagesList: [],
      snapChartTooltipConfig: {
        content: "Click to take snapshot of the chart. ",
        trigger: "hover",
        delay: { show: 500, hide: 500 }
      },
      refreshModal: true,      
    };
  },
  props: {
    modalId: {
      type: String,
      default: "expanded-dashboard"
    },
    modalTitle: {
      type: String,
      default: "Expanded"
    },
    inputIsPricingPower: {
      type: Boolean,
      default: true
    },
    modalContributionChartList: {
      type: Array,
      default: function() {
        return [];
      }      
    },
    clientChart: {
      type: Object,
      default: function() {
        return {};
      }
    },    
    chartTitle: {
      type: String,
      default: ""
    },
    dashboardCardReportPageTypeName: {
      type: String,
      default: ""
    } 
  },
  computed: {
    displayAdvisorButton: function(){
      return typeof this.clientChart != "undefined" && this.clientChart.clientChartId != 0;
    }    
  },
  mounted() {},
  created() {
    this.dashboardInsightModalId = "dashboard-card-" + this.modalId;
  },
  methods: {
    closeModal(name) {
      if(this.$refs[name] != null) {
        this.$refs[name].visible = false;
      }
      this.refreshModal = !this.refreshModal;
    },
    closeSaveChartModal(name) {
      if(this.$refs[name] != null) {
        this.$refs[name].visible = false;
      }
    },
    beforeOpen(){
      this.displayDashboard = true;
    },
    displayInsights(modalId, reportPageTypeName) {
      if (reportPageTypeName == this.dashboardCardReportPageTypeName) {
        this.$refs.insightsDashboardModal.reportPageInput = ReportService.getReportPage(reportPageTypeName, this.reportPagesList);
        this.$refs.insightsDashboardModal.initializeInsights(this.clientChart);
        this.$modal.show(modalId);
      } 
    },    
    getReportPagesCallback(response) {
      this.$root.processServerResponse();
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        this.reportPagesList = response.data.data;
      } else {
        AdminService.displayErrorMessage({ text: response.data.message });
      }
    },    
    saveClientChart(value, message) {
      this.$parent.saveClientChart(value, message);
    },
    updateReportPages(value) {
      this.reportPagesList.push(value);
    },    
    saveAllReportPages() {
      this.$parent.saveReportPage(this.modalId,this.$parent.reportTypeName);
    }
  },
  watch: {}
};
</script>
